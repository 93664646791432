<template>
  <v-card variant="text" class="mt-4 mx-auto">
    <v-form v-model="form" @submit.prevent="next">
      <p class="text-center text-h5">{{ $t('app.Labels.PersonalInformation').toUpperCase() }}</p>
      <v-text-field v-model="visitante.nombres" :rules="[v => !!v || $t('app.Errors.NameValidation')]"
        :label="$t('app.Fields.Name')"></v-text-field>
      <v-text-field v-model="visitante.apellido_paterno" :rules="[v => !!v || $t('app.Errors.LastNameValidation')]"
        :label="$t('app.Fields.LastName')"></v-text-field>
      <v-text-field v-model="visitante.apellido_materno" :label="$t('app.Fields.SecondLastName')"></v-text-field>
      <v-text-field v-model="visitante.fecha_nacimiento" type="date"
        :rules="[v => !!v || $t('app.Errors.BirthDateValidation'), birthdateValidation]"
        :label="$t('app.Fields.Birthdate')"></v-text-field>
      <v-text-field v-if="isMinor" v-model="visitante.apoderado"
        :rules="[v => !!v || $t('app.Errors.AuthorizingParentValidation')]"
        :label="$t('app.Fields.AuthorizingParent')"></v-text-field>
      <v-select v-model="visitante.tipo_documento_visitante_id" :label="$t('app.Fields.TypeIDDocument')"
        :rules="[v => !!v || $t('app.Errors.TypeIDDocumentValidation')]" :items="itemsIdioma" item-value="id"
        :item-title="item => item.descripcion.toUpperCase()"></v-select>
      <v-text-field v-model="visitante.numero_documento" :type="isDni ? 'number' : 'text'" :rules="documentoRules"
        :label="$t('app.Fields.IDDN')" :maxlength="isDni ? 8 : null"></v-text-field>
      <v-select v-model="visitante.tipo_visitante_familiar_id" :label="$t('app.Fields.Relationship')"
        :items="$store.state.tipo_visitante" item-value="id" :item-title="item => item.descripcion.toUpperCase()"
        @change="handleVinculoChange" :rules="[v => !!v || $t('app.Errors.RelationshipValidation')]"></v-select>
      <v-text-field v-if="visitante.tipo_visitante_familiar_id === 5"
        v-model="visitante.detalle_tipo_visitante_familiar" :label="$t('app.Fields.Specify')"></v-text-field>
      <v-text-field v-model="visitante.contacto_emergencia" :label="$t('app.Fields.EmergencyContact')"
        :rules="[v => !!v || $t('app.Errors.PhoneValidation')]"></v-text-field>
      <v-text-field v-model="visitante.telefono_contacto" :label="$t('app.Fields.Phone')"
        :rules="[v => !!v || $t('app.Errors.PhoneValidation')]"></v-text-field>
      <v-select v-model="visitante.tipo_seguro_visitante_familiar_id" :label="$t('app.Fields.Insurance')"
        :items="$store.state.tipo_seguro" @change="handleSeguroChange" item-value="id"
        :item-title="item => item.descripcion.toUpperCase()"
        :rules="[v => !!v || $t('app.Errors.InsuranceValidation')]"></v-select>
      <v-text-field v-if="visitante.tipo_seguro_visitante_familiar_id === 4"
        v-model="visitante.detalle_tipo_seguro_visitante_familiar" :label="$t('app.Fields.Specify')"></v-text-field>
      <v-select v-model="visitante.centro_salud_visitante_familiar_id" :label="$t('app.Fields.HealthCenter')"
        :items="$store.state.centro_salud" @change="handleCentroSaludChange" item-value="id"
        :item-title="item => item.descripcion.toUpperCase()"
        :rules="[v => !!v || $t('app.Errors.HealthCenterValidation')]"></v-select>
      <v-text-field v-if="visitante.centro_salud_visitante_familiar_id === 12"
        v-model="visitante.detalle_centro_salud_visitante_familiar" :label="$t('app.Fields.Specify')"></v-text-field>
      <v-card-actions>
        <v-btn class="mt-2" variant="flat" color="orange-darken-1" @click="atras">{{ $t('app.Buttons.Back') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mt-2" variant="flat" color="success" type="submit">{{ $t('app.Buttons.Next') }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      form: false,
      items_en: [
        { id: 1, descripcion: "DNI" },
        { id: 2, descripcion: "FOREIGNER CARD" },
        { id: 3, descripcion: "PASSPORT" }
      ],
      items_es: [
        { id: 1, descripcion: "DNI" },
        { id: 2, descripcion: "CARNET DE EXTRANJERIA" },
        { id: 3, descripcion: "PASAPORTE" }
      ],
    };
  },
  computed: {
    visitante: {
      get() {
        return this.$store.state.visitante;
      },
      set(newValue) {
        this.$store.commit('SET_VISITANTE', newValue);
      }
    },
    isDni() {
      return this.visitante.tipo_documento_visitante_id === 1;
    },
    documentoRules() {
      const rules = [v => !!v || this.$t('app.Errors.IDDNValidation')];
      if (this.isDni) {
        rules.push(v => v.length === 8 || this.$t('app.Errors.DNIValidation'));
      }
      return rules;
    },
    isMinor() {
      const birthdate = new Date(this.visitante.fecha_nacimiento);
      const today = new Date();
      const age = today.getFullYear() - birthdate.getFullYear();
      const monthDiff = today.getMonth() - birthdate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
        return age - 1 < 18;
      }
      return age < 18;
    },
    itemsIdioma() {
      return this.$i18n.locale === 'es' ? this.items_es : this.items_en;
    },
  },
  methods: {
    next() {
      if (this.form) {
        this.$store.dispatch('guardarDatosPersonales', this.visitante);
      }
    },
    atras() {
      this.$store.commit('SET_VENTANA', 1);
    },
    handleVinculoChange(value) {
      if (value !== 'Otro') {
        this.visitante.detalle_tipo_visitante_familiar = '';
      }
    },
    handleSeguroChange(value) {
      if (value !== 'Otros') {
        this.visitante.detalle_tipo_seguro_visitante_familiar = '';
      }
    },
    handleCentroSaludChange(value) {
      if (value !== 'Otros') {
        this.visitante.detalle_centro_salud_visitante_familiar = '';
      }
    },
    birthdateValidation() {
      return this.isMinor ? !!this.visitante.apoderado || this.$t('app.Errors.AuthorizingParentValidation') : true;
    },

  },

  mounted() {
    this.$store.dispatch('fetchCentroSalud');
    this.$store.dispatch('fetchTipoSeguro');
    this.$store.dispatch('fetchTipoVisitante');
  }
}
</script>
