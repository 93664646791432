import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AppContainer from '@/AppContainer.vue'
import FormView from "@/views/FormView";
import GuardadoSatisfactorioView from "@/views/GuardadoSatisfactorioView.vue";

const routes = [
  {
    path: "/",
    component: AppContainer,
    children: [
      {
        path: "",
        component: HomeView,
        name: "home",
      },
    ],
  },
  {
    path: "/form",
    component: AppContainer,
    children: [
      {
        path: "",
        component: FormView,
        name: "Form",
      },
    ],
  },
  {
    path: "/success",
    component: AppContainer,
    children: [
      {
        path: "",
        component: GuardadoSatisfactorioView,
        name: "success",
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
