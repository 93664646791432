<template>
  <v-card variant="text" class="mt-4 mx-auto">
    <v-card-text>
      <v-sheet class="pa-6  mx-auto">
        <h4 class="text-h5 font-weight-bold mb-4">{{ $t('app.Labels.PresentationHeader') }}</h4>
        <p class="text-justify">
          {{ $t('app.Labels.PresentationBody1') }}
          <br><br>
          {{ $t('app.Labels.PresentationBody2') }}
          <br><br>
          {{ $t('app.Labels.PresentationBody3') }}
          <br><br>
          {{ $t('app.Labels.PresentationBody4') }}
        </p>
      </v-sheet>
    </v-card-text>
    <v-card-actions>
      <v-btn class="mt-2" @click="next" variant="flat" color="success" type="submit" block
        :text="$t('app.Buttons.Next')"></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {

    };
  },
  computed: {
    pdfUrl() {
      const lang = this.$i18n.locale; // Obtiene el idioma actual
      return process.env.BASE_URL + `detalle_${lang.toUpperCase()}.pdf`;
    }
  },
  methods: {
    next() {
      this.$store.commit('SET_VENTANA', 2);
    }
  },
  watch: {

  }
}
</script>
